import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import "../Css/LectureScreen.css";
import { useSelector } from "react-redux";
import { LoggedInUser } from "../Components/Features/user/UserSlice";

export default function LectureScreen() {
  const { chapter } = useParams();
  const _Id = useSelector(LoggedInUser);
  const videoRef = useRef(null);
  const floatingTextRef = useRef(null);
  const [floatingText, setFloatingText] = useState("Initial Watermark Text");
  const randomNumber = useRef(0);
  const randomNumber1 = useRef(0);
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  // Handle window resize
  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Random number generation
  useEffect(() => {
    const generateRandomNumber = () => {
      randomNumber.current = Math.floor(100 + Math.random() * 100);
      randomNumber1.current = Math.floor(100 + Math.random() * 100);
    };

    const interval = setInterval(generateRandomNumber, 1000);
    return () => clearInterval(interval);
  }, []);

  // Update floating text position on mouse move
  useEffect(() => {
    const handleMouseMove = () => {
      if (floatingTextRef.current) {
        floatingTextRef.current.style.left = `${randomNumber.current}px`;
        floatingTextRef.current.style.top = `${randomNumber1.current}px`;
      }
    };

    const video = videoRef.current;
    if (video) {
      video.addEventListener("mousemove", handleMouseMove);
    }

    return () => {
      if (video) {
        video.removeEventListener("mousemove", handleMouseMove);
      }
    };
  }, []);

  // Set user ID as floating text after a timeout
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (_Id) setFloatingText(_Id.email);
    }, 1000);

    return () => clearTimeout(timeout);
  }, [_Id]);

  // Render video and floating text only if user is logged in
  return (
    <>
      {_Id ? (
        <div>
        <div style={{display:'flex', justifyContent:'center',alignItems:'center', width:'100%'}}>
          <video
            ref={videoRef}
            src={`${process.env.REACT_APP_SERVER_URL}/video?_id=${chapter}`}
            width="600"
            controls
            // style={{ display: "block" }}
            controlsList="nodownload"
            className="video-section"
          />
          <div
            id="floatingText  floating-text"
            ref={floatingTextRef}
            style={{
              position: "absolute",
              color: "white",
              background: "rgba(0, 0, 0, 0.5)",
              padding: "5px",
              borderRadius: "5px",
              pointerEvents: "none", // Ensure the text doesn't block mouse interactions
            }}
          >
            {floatingText}
          </div>
        </div></div>
      ) : (
        <p
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "90vh",
          }}
        >
          Please Login
        </p>
      )}
    </>
  );
}
