

import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { loginUserAsync } from "../../Components/Features/user/UserSlice";
import { useNavigate } from "react-router-dom";
import "../../Css/Login.css";


export default function LogIn() {
  const [Error, setError] = useState("");
  const [state, setState] = useState({
    email: "",
    Password: "",
  });


  console.log(process.env.REACT_APP_SERVER_URL);
  

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    console.log(event.target.value);
    setState((preProps) => ({
      ...preProps,
      [name]: value,
    }));
  };


  console.log(process.env.REACT_APP_SERVER_URL);
  
  const handleSubmit = async (event) => {
    setError("");
    event.preventDefault();
    if (!state.email) {
      setError("Please Fill Email");
      return;
    }
    if (!state.Password) {
      setError("Password is required");
      return;
    }
    dispatch(loginUserAsync({ email: state.email, password: state.Password }));
    setTimeout(navigate("/"), 2000);
  };
  return (
    <div>
      <div className="login-page">
        <p className="login-header">Welcome Again TO Bhokal Trader</p>
        <p className="login-sub-Head">Please Log in To Resume your Journey</p>

        <div>
          <div className="login-form">
            <form onSubmit={handleSubmit} style={{ margin: "20px" }}>
              <div>
                <label className="login-detail" style={{ marginRight: "10px" }}>
                  Email Address:
                  <input
                    className="login-field"
                    type="email"
                    name="email"
                    
                    value={state.email}
                    onChange={handleInputChange}
                    style={{ marginLeft: "5px" }}
                  />
                </label>
              </div>
              <div>
                <label className="login-detail" style={{ marginRight: "10px" }}>
                  Password:
                  <input
                    className="login-field"
                    type="password"
                    name="Password"
                    value={state.Password}
                    onChange={handleInputChange}
                    autoComplete="off"
                    style={{ marginLeft: "5px" }}
                  />
                </label>
              </div>
              <p className="error">{Error}</p>
              <button className="login-button1" type="submit">
                Log In
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
