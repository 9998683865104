import React from "react";
import { Link } from "react-router-dom";
import "../Css/LecterCard.css";
import { useSelector } from "react-redux";
import { ClassesData } from "./Features/Classes/ClassSlice";
import ReactLoading from "react-loading";

export default function Lecters() {
  const data = useSelector(ClassesData);
  console.log(data,"Workingin lecters");

  // Fallback for data not available
  if (!data) {
    return (
      <div className="main">
        <ReactLoading type="balls" color="#0000FF" height={100} width={50} />
      </div>
    );
  }

  return (
    <div className="main">
      <div className="lecters">
        {data.map(({Id, _id
, Url, Video_No
, Video_Title
 }) => {
  

          return (
            <div key={Id} className="lecter-box">
              <div className="flex">
                <div>
                  <p className="lecter-title">
                    Lecter {Video_No

} : {Video_Title}
                  </p>
                </div>
                <div className="button-center-box">
                  <Link to={`/courses/${_id
}/${Url
}`}>
                    <div className="lecter-button">Watch Now</div>
                  </Link>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
