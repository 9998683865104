import React, { useEffect } from "react";
import "../../Css/Home.css";
import { FaTelegramPlane, FaYoutube, FaFacebookF } from "react-icons/fa";

import know from "../../imgs/img1.jpg";
import { useDispatch, useSelector } from "react-redux";
import { LoggedInUser } from "../../Components/Features/user/UserSlice";
import { Link } from "react-router-dom";
import { FaUserCircle } from "react-icons/fa";
import { GetCourseAsync } from "../../Components/Features/Course/CourseSlice";

const reviews = [
  {
    name: "Student 1",
    profession: "Batch 1",
    review:
      "Sir apke according jo k profitable rahi.. Orr practice ja h... Thank you too much dear ❤️ Sir ..  Ap mere life k terning point bane 😌 😌 .. Again thanks",
  },
  {
    name: "Chetan Rathod",
    profession: "Batch 1",
    review:
      "Thank you 🙏 sir app ke video dekh ke trade le sir app ke video 100 daar dekh ho learn kiya aaj ya result h app ke samne thank you sir 🙏 Aap ke video bahut knowledgeable h sir You Tube par h",
  },
  {
    name: "Md Aftab",
    profession: "Batch 1",
    review:
      `Hello sir ap kaise hai?.. Apke Knowledge  Thanks  again and again.. Apke YouTube videos dekh kar accuracy bhut achii ho gya mera.. You are best teacher in the world`,
  },
  {
    name: "Student 2",
    profession: "Batch 1",
    review:
      "Sir ur knowledge is so adorable.. i,m just getting wow accuracy.. i,m felling so blessed to become to become ur student.. ur just making people's life best.. giving them a true price action concept that works all the time in the market.. We just need patince and practice.. Never Giveup and thanks so much sir",
  },
];

export default function Home() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(GetCourseAsync());
  }, []);
  return (
    <div>
      <div>
        <div className="section1">
          <div className="section1-a">
            <p className="section-sub-header">Proffesional</p>
            <p className="section-header">Binary Trading</p>
            <p className="section-about">
              Before you can choose an actual dog trainer, I believe you must
              first decide what kind of bond you want to establish with your
              dog.
            </p>
            <div className="buttons">
              {/* <div className="button1">
                <p>Purchase Now</p>
              </div> */}
              <Link className="button2 remove_underline" to="/Courses">
                <p className="details">More Details</p>
              </Link>
            </div>
          </div>
        </div>

        <div className="social-media">
          <Link
            to={"https://www.youtube.com/@BhokalTrader"}
            className="youtube Home-circle"
          >
            <FaYoutube color="#FF0000" size={40} />
          </Link>
          <Link
            to="https://t.me/bhokaltradero"
            className="Home-circle telegram"
          >
            <FaTelegramPlane color="white" size={40} />
          </Link>
          <Link
            to="https://t.me/bhokaltradero"
            className="Home-circle facebook"
          >
            <FaFacebookF color="white" size={40} />
          </Link>
        </div>

        <div className="section2">
          <div className="center w-500-1">
          <iframe
  width="560"
  height="315"
  className="section2-img"
  src="https://www.youtube.com/embed/g2Ygvt_HaEc?si=wAN1AUBCEkZyDQSl"
  title="YouTube video player"
  style={{ border: "none" }} // Use CSS for styling
  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
  referrerpolicy="strict-origin-when-cross-origin"
  allowFullScreen
></iframe>

          </div>
          <div className="section2-details w-500">
            <p className="section2-sub-head">Know</p>
            <p className="section2-head">About Us</p>
            <ul className="section2-1-details">
              <li>
                <p>
                  {" "}
                  am a passionate binary trader with expertise in predicting
                  market trends and making strategic investments.
                </p>
              </li>
              <li>
                <p>
                  I am a passionate binary trader with expertise in predicting
                  market trends and making strategic investments.
                </p>
              </li>
              <li>
                <p>
                  I am a passionate binary trader with expertise in predicting
                  market trends and making strategic investments.
                </p>
              </li>
              <li>
                <p>
                  I am a passionate binary trader with expertise in predicting
                  market trends and making strategic investments.
                </p>
              </li>
            </ul>

            <Link to={"About"} className=" remove_underline section2-button">
              <p>Know More</p>
            </Link>
          </div>
        </div>
        <div className="section3">
          <div className="section3-content w-500">
            <p className="section2-sub-head">Get</p>
            <p className="section2-head">Our Courses</p>
            <ol className="Course-detail">
              <li>Course:- Binary Trading</li>
              <li>Duratin:- 6 Month</li>
              <li>Recorded Video</li>
              <li>Live Classes</li>
              <li>Live Demo</li>
              <li>Price:- ₹4,999.00</li>
            </ol>

            <div className="sections-buttons">
              <Link
                to="https://t.me/bhokaltradero"
                className="sections3-button1 remove_underline"
              >
                <p>Purchase Now</p>
              </Link>
              <Link
                to="https://t.me/bhokaltradero"
                className="sections3-button2 remove_underline"
                style={{ color: "#023E17" }}
              >
                <p>Get Details</p>
              </Link>
            </div>
          </div>

          <div className="center w-500">
            <img src={know} className="section3-img" />
          </div>
        </div>

        <div className="section4">
          <div className="section4-box">
            <p className="section4-box-sub-head">Our</p>
            <p className="section4-box-head">Honest Reviews</p>
            <p className="section4-box-des">
              You’ll enjoy knowing our dedicated team will do whatever is needed
              to keep your pets happy, healthy and safe when you’re away from
              home.
            </p>

            <div className="slide-contanier">
              <div className="slide-content">
                <div className="wrap-wrapper">
                  
                {reviews.map((review, index) => (
  <div className="card" key={index}>
    <div className="image-content">
      <span className="overlay"></span>
      <div className="card-image">
        <FaUserCircle size={100} />
      </div>
    </div>
    <div className="card-content">
      <h3 className="name">{review.name}</h3>
      <p className="Profession">{review.profession}</p>
      <p className="reviews">{review.review}</p>
    </div>
  </div>
))}
                      
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
