import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

export default function CheckFotter({children}) {
   const location = useLocation();
   const [showFotter ,setShowFotter] = useState(false)
   useEffect(()=>{
      if(location.pathname === '/login'){
         setShowFotter(false)
      }
      else{
         setShowFotter(true)
      }

   },[location])
  return (
    <div>{showFotter && children}</div>
  )
}
