import React, { useEffect, useState } from "react";
import "../../Css/Course.css";
import CourseComponent from "../../Components/CourseComponent";
import { useDispatch, useSelector } from "react-redux";
import { Coursesdata, GetCourseAsync } from "../../Components/Features/Course/CourseSlice";
import ReactLoading from "react-loading";

export default function Courses() {
  const data = useSelector(Coursesdata);
  console.log(data);
  
  const dispatch = useDispatch();
  const [error, setError] = useState(null);

  useEffect(() => {
    dispatch(GetCourseAsync()).catch((err) => setError('Failed to load courses.'));
  }, [dispatch]);

  return (
    <div className="Contact-main">
      {error && <p>{error}</p>}
      {Array.isArray(data?.cousres) ? (
        data.cousres.map((item) => (
          <CourseComponent key={item.id} data={item} />
        ))
      ) : (
        <ReactLoading type="balls" color="#0000FF" height={100} width={50} />
      )}
    </div>
  );
}
