export function AddContact(data) {
  return new Promise(async (resolve, reject) => {
    try {

      console.log(data)
      const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/contact`, {
        method: "POST",
        body: JSON.stringify(data),
        headers: { "content-type": "application/json" },
      });
      if (response.ok) {
        const data = await response.json();
        resolve({ data });
      } else {
        const err = await response.text();
        reject(err);
      }
    } catch (error) {
      reject(error);
    }
  });
}


export function NewsLetter(data) {
  return new Promise(async (resolve, reject) => {
    try {

      console.log(data)
      const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/contact/newsletter`, {
        method: "POST",
        body: JSON.stringify(data),
        headers: { "content-type": "application/json" },
      });
      if (response.ok) {
        const data = await response.json();
        resolve({ data });
      } else {
        const err = await response.text();
        reject(err);
      }
    } catch (error) {
      reject(error);
    }
  });
}

// export function NewsLetter(data){
//    return new Promise(async (resolve,reject)=> {
//       try{
//          console.log(data)
//          const response = await fetch('http://localhost:8000/subscription/newsletter',{
//             method:'POST',
//             body:JSON.stringify(data),
//             headers:{'content-type':'application/json'}
//          });
//          if(response.ok){
//             const data = await response.json();
//             resolve({data})
//          }
//          else{
//             const err = await response.text();
//             reject(err)
//          }
//       }
//       catch (err){
//          reject(err)
//       }
//    })
// }
