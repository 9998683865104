import React from 'react';
import '../../Css/AboutUs.css';

export default function AboutUs() {
  return (
    <div className="about-container">
      <div className="about">
        <div className="about-box">
          <h2>About Us - Bhokal Trader</h2>
          <p><strong>Journey</strong></p>
          <p>
            Bhokal Trader is more than just a name in the binary trading community—it’s a movement dedicated to financial growth and empowerment. 
            As a professional binary trader, Bhokal Trader has consistently demonstrated exceptional expertise and a passion for teaching. From humble beginnings, the mission has always been to simplify the complexities of trading and provide a roadmap for individuals seeking financial independence. Whether through paid courses, free educational videos, or live classes, Bhokal Trader has consistently prioritized quality and transparency, earning the trust and respect of a rapidly growing community.
          </p>
          <p><strong>Achievements That Speak Volumes</strong></p>
          <ul>
            <li>Over 1000 students have achieved remarkable success through personalized guidance in paid courses.</li>
            <li>250+ live trading sessions have been conducted, where real-time strategies and expert insights were shared.</li>
            <li>More than 1000 students have reached their trading goals by leveraging free, high-quality YouTube courses.</li>
          </ul>
          <p>
            These accomplishments reflect a deep commitment to the success of each learner, regardless of their background or prior experience. Every session, video, and course is designed to empower individuals with the skills and mindset required for sustained success in trading.
          </p>
          <p><strong>Vision for the Future</strong></p>
          <p>
            At Bhokal Trader, the vision is bold yet attainable: to empower more than 50,000 individuals over the next five years. This goal is rooted in a desire to foster a generation of financially literate, confident, and successful traders who can achieve financial freedom. Bhokal Trader envisions a world where financial knowledge is accessible to all, bridging the gap between ambition and achievement.
          </p>
          <p>
            With a continued focus on innovation, community engagement, and unwavering dedication, Bhokal Trader is determined to expand the reach and impact of the platform, ensuring no aspiring trader is left behind.
          </p>
          <p>
            Join Bhokal Trader on this transformative journey and become a part of a thriving community that believes in the power of knowledge and persistence to unlock limitless potential.
          </p>
        </div>
      </div>
    </div>
  );
}
