import React, { useState } from "react";
import { Link } from "react-router-dom";
import { MdHome, MdAddCall, MdOutlineLaptopChromebook } from "react-icons/md";
import { RxCross2 } from "react-icons/rx";
import { IoMdMenu } from "react-icons/io";
import { BsFillPeopleFill } from "react-icons/bs";
import { FaUserCircle } from "react-icons/fa";
import "../../Css/NavBar.css";
import { useSelector } from "react-redux";
import { LoggedInUser } from "../../Components/Features/user/UserSlice";

export default function Navbar() {
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const user = useSelector(LoggedInUser);
  return (
    <div>
      <div className="navbar">
        <div>
          <Link to={'/'} className="site-name">Bhokal Trader</Link>
        </div>
        <div className="menu-link">
          <ul className="menu-link-desktop">
            <li>
              <Link to="/" className="bars">
                <MdHome className="icon" />
                Home
              </Link>
            </li>
            <li>
              <Link to="/Courses">
                <MdOutlineLaptopChromebook className="icon" />
                Courses
              </Link>
            </li>
            {/* <li>
            <Link to="/Live-classes">
              {" "}
              <MdOndemandVideo className="icon" />
              Live Classes
            </Link>
          </li> */}
          <li>
            <Link to="/About">
              {" "}
              <BsFillPeopleFill  className="icon"/>
              About us
            </Link>
          </li> 
            <li>
              <Link to="/contact-us">
                {" "}
                <MdAddCall className="icon" /> Contact us
              </Link>
            </li>
            <div
              style={{
                width: "150px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {user != null ? (
                <FaUserCircle size={40} color="#2C8C87" />
              ) : (
                <Link to="/login" className="login-text">
                  <div className="login-button">
                    <p>Log In</p>
                  </div>
                </Link>
              )}
            </div>
          </ul>
          <div className="menu-link-mobile">
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "10px",
              }}
            >
              {user != null ? (
                <FaUserCircle size={40} color="#2C8C87" />
              ) : (
                <Link to="/login" style={{ textDecoration: "none" }}>
                  <div className="login-text-mobile">
                    <p>Log In</p>
                  </div>
                </Link>
              )}
              <a>
                {!showMobileMenu ? (
                  <IoMdMenu
                    size={30}
                    color="#2C8C87"
                    onClick={() => setShowMobileMenu(!showMobileMenu)}
                  />
                ) : (
                  <RxCross2
                    size={30}
                    color="#2C8C87"
                    onClick={() => setShowMobileMenu(!showMobileMenu)}
                  />
                )}
              </a>
            </div>
          </div>
        </div>
      </div>
      {showMobileMenu && (
        <div className="mobile-Menus">
          <ul className="menus-mobile">
            <li>
              <Link to="/" className="bars">
                Home
              </Link>
            </li>
            <li>
              <Link to="/Courses">Courses</Link>
            </li>
            <li>
              <Link to='/About' className="bard">Avout Us</Link>
            </li>
            {/* <li>
            <Link to="/Live-classes">
              {" "}
             
              Live Classes
            </Link>
          </li> */}
            <li>
              <Link to="/contact-us"> Contact us</Link>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
}
