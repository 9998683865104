import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./Screens/Main-Screen/Home";

import Courses from "./Screens/Main-Screen/Courses";
import Navbar from "./Screens/Navigation/Navbar";
import Fotter from "./Screens/Navigation/Fotter";
import ContactUs from "./Screens/Main-Screen/ContactUs";
import LiveClasses from "./Screens/Main-Screen/LiveClasses";
import LogIn from "./Screens/Authentication/LogIn";
import LectureScreen from "./Components/LectureScreen";
import Lecters from "./Components/Lecters";
import CheckNavBar from "./Checking/CheckNavBar";
import CheckFotter from "./Checking/CheckFotter";
import "../src/Css/Global.css";
import { useEffect } from "react";
import AboutUs from "./Screens/Navigation/AboutUs";
import PageNotFound from "./Screens/Authentication/PageNotFound";
import { checkAuthAsync } from "./Components/Features/user/UserSlice";
import { useDispatch } from "react-redux";
function App() {
  // async function checkuser() {
  //    await fetch(`http://localhost:8000/user/check`, {
  //     method: "POST",
  //     body: JSON.stringify(),
  //    headers: {"content-type": "application/json"},
  //   });
  // }

  const dispath = useDispatch();

  useEffect(() => {
    console.log("working in APP.js");
    dispath(
    checkAuthAsync()
    )
  }, []);
  return (
    <BrowserRouter>
      <CheckNavBar>
        <Navbar />
      </CheckNavBar>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/Live-classes" element={<LiveClasses />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/login" element={<LogIn />} />
        <Route path="/courses" element={<Courses />} />
        <Route path="/courses/:id" element={<Lecters />} />
        <Route path="/About" element={<AboutUs />} />
        <Route path="/courses/:id/:chapter" element={<LectureScreen />} />
        <Route path="/*" element={<PageNotFound></PageNotFound>}/>
      </Routes>
      <CheckFotter>
        <Fotter />
      </CheckFotter>
    </BrowserRouter>
  );
}
export default App;
