import React, { useState } from "react";
import { IoLogoTwitter } from "react-icons/io";
import { MdEmail } from "react-icons/md";
import { FaTelegramPlane } from "react-icons/fa";

import "../../Css/Fotter.css";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { AddNewsletterAsync } from "../../Components/Features/Contact/ContactSlice";

export default function Fotter() {
  const [state, setState] = useState({
    email_address: "",
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    console.log(event.target.value);
    setState((preProps) => ({
      ...preProps,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!state.email_address) {
      alert("Please Provide your Email");
      return;
    }
    dispatch(AddNewsletterAsync({ email_address: state.email_address }));
    toast("Your NewsLetter Is Accepted");
    navigate("/");
  };
  return (
    <div className="main-fotter">
      <ToastContainer />
      <div className="fotter">
        <div className="boxes thought">
          <h3>Thoughts</h3>
          <ul>
            <li>
              <IoLogoTwitter color="#37FFFF" size={"28px"} />

              <p>
                Success in trading isn’t about luck—it’s about learning,
                discipline, and persistence..
              </p>
            </li>
            <li>
              <IoLogoTwitter color="#37FFFF" size={"28px"} />

              <p>
                "Every expert trader was once a beginner. Start today, and grow
                every day."
              </p>
            </li>
            <li>
              <IoLogoTwitter color="#37FFFF" size={"28px"} />

              <p>
                "Mistakes are lessons in disguise; embrace them and keep moving
                forward."
              </p>
            </li>
          </ul>
        </div>
        <div className="contact boxes">
          <h3>Our Contacts</h3>
          <div className="contact-1">
            <div className="contact-options">
              <MdEmail color="#37FFFF" size={24} />
              <p className="mail">
                <a href="mailto:Example@gmail.com" className="mail-text">
                  Example@gmail.com
                </a>
              </p>
            </div>
            <div className="contact-options">
              <FaTelegramPlane color="#37FFFF" size={24} />
              {/* <IoCall color="#37FFFF" size={24} /> */}
              <p>
                <a href="https://t.me/bhokaltradero" className="mail-text">
                  Bhokal Trader
                </a>
              </p>
            </div>
          </div>
        </div>
        <div className="boxes">
          <h3>Newsletter</h3>
          <p className="subscribe-text">
            Subscribe to our Newsletter to be updated, we promise not to spam.
          </p>
          <form onSubmit={handleSubmit}>
            <div className="email-text">
              <input
                type="email"
                placeholder="Your Email"
                name="email_address"
                value={state.email_address}
                onChange={handleInputChange}
                className="email-text"
              />
            </div>

            <button className="F-button">Subscribe</button>
          </form>
        </div>
      </div>
      <div className="copyright-box">
        <div className="line"></div>
        <div className="copyright-div">
          &#169; <p>COPYRIGHT 2024 ALL RIGHTS RESERVED</p>
        </div>
      </div>
    </div>
  );
}
