import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export default function CheckNavBar({ children }) {
  const location = useLocation();
  const [showNavBar, SetshowNavBar] = useState(false);

  useEffect(() => {
    if (location.pathname === "/login" || location.pathname ===`/courses/:id/:chapter`) {
      SetshowNavBar(false);
    } else {
      SetshowNavBar(true);
    }
  }, [location]);
  return <div>{showNavBar && children}</div>;
}
