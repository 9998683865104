import {configureStore} from '@reduxjs/toolkit'
import authReducer from './Features/user/UserSlice'
import contactReducer from '../Components/Features/Contact/ContactSlice'
import CourseReducer from '../Components/Features/Course/CourseSlice'

import ClassesReducer from '../Components/Features/Classes/ClassSlice'
export const store = configureStore({
   reducer:{
      user:authReducer,
      Contact:contactReducer,
      Course:CourseReducer,
      Class:ClassesReducer
      
   }
})