import React from "react";
import { Link } from "react-router-dom";
import "../Css/CourseCard.css";
import { useDispatch, useSelector } from "react-redux";
import { LoggedInUser } from "./Features/user/UserSlice";
import { getClassesAsyc } from "./Features/Classes/ClassSlice";

export default function CourseComponent({ data }) {
  const {  Course_Name, thumbnail, Course_Duration, Recorded_Video, Live_Classes, Demo_Classes, price, _id } = data;
  console.log(data);
  
  const logined = useSelector(LoggedInUser);
  console.log(logined);
  
  const dispatch = useDispatch();

  // Function to Get Whole Classes
  function handleClick(courseId) {
    dispatch(getClassesAsyc({ Id: courseId }));
  }

  return (
    <div key={_id} className="course-box">
      <div className="w-500 center">
        <img src={`${process.env.REACT_APP_SERVER_URL}/image?url=${thumbnail}`} className="Course-thumbnail" alt="course-thumbnail" />
      </div>
      <div className="content-box center w-500">
        <p className="title">{Course_Name}</p>
        <ol>
          <li>Duration: {Course_Duration} Month</li>
          {Recorded_Video && <li>Recorded Video</li>}
          {Live_Classes && <li>Live Classes</li>}
          {Demo_Classes && <li>Live Demo</li>}
          <li>Price: ₹{price}</li>
        </ol>

        <div className="button-course">
          {logined && logined.Batch_No
 === _id ? (
            <Link
              onClick={() => handleClick(_id)}
              to={{
                pathname: `/courses/${_id}`,
                state: { _id: _id,thumbnail:thumbnail },
              }}
            >
              Watch Now
            </Link>
          ) : (
            <Link to="https://t.me/bhokaltradero">Purchase Now</Link>
          )}
        </div>
      </div>
    </div>
  );
}
