import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { checkAuth, loginUser } from "./UserAPI";

const initialState = {
  status: 'idle',
  user: null,
  error: "",
};

// Async Thunks
export const loginUserAsync = createAsyncThunk(
  'user/loginUser',
  async (loginInfo, { rejectWithValue }) => {
    try {
      const response = await loginUser(loginInfo);
      return response.data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.response ? error.response.data : error.message);
    }
  }
);

export const checkAuthAsync = createAsyncThunk(
  'user/checkAuth', 
  async (_, { rejectWithValue }) => {
    try {
      const response = await checkAuth();
      return response.data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.response ? error.response.data : error.message);
    }
  }
);

// Slice
export const authSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Login user async cases
      .addCase(loginUserAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(loginUserAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.user = action.payload;
        state.error = ""; // Reset error if login is successful
      })
      .addCase(loginUserAsync.rejected, (state, action) => {
        state.status = 'idle';
        state.error = action.payload || "Login failed"; // Set error message
      })
      
      // Check auth async cases
      .addCase(checkAuthAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(checkAuthAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.user = action.payload || null; // Clear user on unsuccessful check
        state.error = ""; // Reset error if authentication is successful
      })
      .addCase(checkAuthAsync.rejected, (state, action) => {
        state.status = 'idle';
        state.user = null; // Set user to null on failed authentication
        state.error = action.payload || "Authentication failed"; // Set error message
      });
  }
});

// Selectors
export const LoggedInUser = (state) => state.user.user;

export default authSlice.reducer;
