import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { getClasses } from "./ClassAPI"


const initialState = {
   status :'idl',
   data : null,
   error:""
}

export const getClassesAsyc = createAsyncThunk(
   'classes/get',

   async(data)=>{
      try{
         console.log('get Classes API called')
         const response  = await getClasses(data)
         return response.data;

      }
      catch(err){
         console.log(err);
         
      }
   }
)

export const ClassesSlice = createSlice({
   name:"classes",
   initialState,
   reducers:{},
   extraReducers: (builder)=>{
      builder
      .addCase(getClassesAsyc.pending,(state)=>{
         state.status = 'pending';
      })
      .addCase(getClassesAsyc.fulfilled ,(state,actions)=>{
         console.log(actions.payload)
         state.status = 'idl';
         state.data = actions.payload;
      })
   }
})

export const ClassesData = (state) => state.Class.data;

export default ClassesSlice.reducer;