import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { GetCourse } from "./CourseAPI"

const initialState = {
   status:'idl',
   data: null,
   error:""
}

export const GetCourseAsync = createAsyncThunk(
   'course/get',
   async ()=>{
      try {
         console.log('getCourseAsync API Called')
         const response = await GetCourse();
         return response.data;
      }
      catch (Error){
         console.log(Error)
         return Error
      }
   }
)
export const CourseSlice = createSlice({
   name:"course",
   initialState,
   reducers:{},
   extraReducers: (builder)=>{
      builder
      .addCase(GetCourseAsync.pending ,(state)=>{
         state.status = "Pending"
      })
      .addCase(GetCourseAsync.fulfilled,(state,actions)=>{
         state.status = 'idl';
         state.data= actions.payload;
      })
   }
})

export const Coursesdata = (state) => state.Course.data

export default CourseSlice.reducer;