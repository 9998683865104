import React from 'react';
import '../../Css/PageNotFound.css';

export default  function PageNotFound  () {
  return (
    <div className="not-found-container not-found-container-1">
      <div className="error-code">404</div>
      <div className="error-message">
        <h1>Oops! Page Not Found</h1>
        <p>The page you are looking for doesn’t exist or has been moved.</p>
        <a href="/" className="home-button">Go Back Home</a>
      </div>
    </div>
  );
};




