import React, { useState } from "react";
import "../../Css/ContactUs.css";
import { CreateQueryAsync } from "../../Components/Features/Contact/ContactSlice";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

export default function ContactUs() {
  const [Error, setError] = useState("");
  const [state, setState] = useState({
    Full_Name: "",
    email: "",
    Message: "",
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setState((preProps) => ({
      ...preProps,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    setError("");
    event.preventDefault();

    if (!state.Full_Name) {
      setError("Please Fill Name");
      return;
    }
    if (!state.email ) {
      setError("Please provide an email address");
      return;
    }

    dispatch(
      CreateQueryAsync({
        Name: state.Full_Name,
        email: state.email,
        Message: state.Message,
      })
    );

    toast("Your Query Is Accepted", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });

    setState({
      Full_Name: "",
      email_addres: "",
      Message: "",
    });

    setTimeout(() => navigate("/"), 2000);
  };

  return (
    <div className="contact-page">
      <ToastContainer />
      <p className="contact-sub-Head">Get In Touch with Us</p>
      <p className="contact-Head">Contact Details</p>

      <form className="contact-form" onSubmit={handleSubmit}>
        <p className="contact-form-heading">Contact Form</p>
        <div className="inputs-1">
          <div className="inputs">
            <div>
              <label className="Input-detail">
                Full Name:- 
                <input
                  placeholder="Enter Your Name"
                  type="text"
                  name="Full_Name"
                  value={state.Full_Name}
                  onChange={handleInputChange}
                  className="input-field"
                />
              </label>
            </div>
            <div>
              <label className="Input-detail">
                Email Address:- 
                <input
                  placeholder="Enter Your Email Address"
                  type="email"
                  name="email"
                  value={state.email}
                  onChange={handleInputChange}
                  className="input-field"
                />
              </label>
            </div>
            <div>
              <label className="Input-detail">
                Message:- 
                <textarea
                  rows="4"
                  cols="50"
                  name="Message"
                  value={state.Message}
                  onChange={handleInputChange}
                  placeholder="Type Your Message"
                  className="input-field"
                />
              </label>
            </div>
          </div>
          <p className="error">{Error}</p>
          <div>
            <button className="contact-button">Send message</button>
          </div>
        </div>
      </form>
    </div>
  );
}
