import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AddContact, NewsLetter } from "./ContactAPI";


const initialState = {
   status:'idl',
   data:null,
   error:""
};

export const CreateQueryAsync = createAsyncThunk(
   'query/query',
   async (data,{rejetWithValue}) =>{
      try {
         const response = await AddContact(data);
         return response.data;
      } catch (error){
         console.log(error);
         return rejetWithValue(error)
      }
   }
)


export const AddNewsletterAsync = createAsyncThunk(
   'query/newsletter',
   async (data , {rejectWithValue}) =>{
      try{
         const response = await NewsLetter(data);
         return response.data;

      }
      catch (Error)
      {
         console.log(Error);
         return rejectWithValue(Error)

      }
   }
)
export const contactSlice = createSlice({
   name:"contact",
   initialState,
   reducers:{},
   extraReducers:(builder)=>{
      builder.addCase(CreateQueryAsync.pending,(state)=>{
         state.status = 'loading';
      })
      .addCase(CreateQueryAsync.fulfilled , (state, action)=>{
         state.status = 'idle';
         state.data = action.payload

      })
      .addCase(CreateQueryAsync.rejected , (state, action)=>{
         state.status = 'idle';
         state.error =action.payload;
      })
      .addCase(AddNewsletterAsync.pending,(state)=>{
         state.status = "pending";
      })
      .addCase(AddNewsletterAsync.fulfilled,(state,action)=>{
         state.status = 'idl';
         state.data = action.payload;
      })
   }
})


export const ContactDetails = (state) => state.contact.data
export default contactSlice.reducer;